<template>
    <section class="app">
        <web-header active="product" />

        <section class="banner">
            <div class="content">
                <div class="text">
                    <h2>高效完整的应用研发</h2>
                    <p>专注于人工智能与云项目研发</p>
                    <p>企业传统平台云端化</p>
                    <p>大型线上活动研发与实施</p>
                    <p>活动策划及研发落地</p>
                </div>
                <div class="logo">
                    <p>class <span style="color:#E5C07B">Hello{</span></p>
                    <p style="text-indent: 2em;">private <span style="color:#E5C07B">String</span><span style="color:#E06C75"> name</span><span style="color:#ABB2BF">;</span></p>
                    <p style="text-indent: 2em;">public static void <span style="color:#61AFEF">main</span>(<span style="color:#E5C07B">String</span><span style="color:#56B6C2">[]</span><span style="color:#E06C75"> args</span>) {</p>
                    <p style="text-indent: 4em;"><span style="color:#E5C07B">System<span style="color:#ABB2BF">.</span>out</span><span style="color:#ABB2BF">.</span><span style="color:#61AFEF">printIn</span>(<span style="color:#98C379">"Hello Srcker"</span>)</p>
                    <p style="text-indent: 2em;">}</p>
                    <p>}</p>
                </div>
            </div>
        </section>
        
        <div class="apps">
            <h1>应用开发</h1>
            <p class="desc">10年研发经验 让您的想法"编程"现实</p>
            <div class="tabs">
                <div class="item active">小程序开发</div>
                <div class="item">H5/微信开发</div>
                <div class="item">APP开发</div>
                <div class="item">物联网/硬件开发</div>
            </div>

            <div class="content">
                <div style="background:#EDF5FC" class="item">
                    <div class="label"><weixin-mini-app theme="outline" />小程序开发</div>
                    <h2>在线教育</h2>
                    <p class="tags">在线答题 | 在线直播 | 错题分析 | 课程学习</p>
                    <div class="image">
                        <img src="https://images.qiniu.fnctory.com/bd4b68026f444b3d1fe1ff10cb8619f5.PNG" />
                    </div>
                </div>
                <div style="background:#FEF5E6" class="item">
                    <div class="label"><weixin-mini-app theme="outline" />小程序开发</div>
                    <h2>在线商城</h2>
                    <p class="tags">二级分销 | 在线购买 | 订单物流</p>
                    <div class="image">
                        <img src="https://images.qiniu.fnctory.com/13780f0a87e30a52bec88b85ab425ecd.PNG" />
                    </div>
                </div>
                <div style="background:#FEF2F0" class="item">
                    <div class="label"><weixin-mini-app theme="outline" />小程序开发</div>
                    <h2>营销活动</h2>
                    <p class="tags">开发新客户 | 活跃老用户</p>
                    <div class="image">
                        <img src="https://wxa.wxs.qq.com/images/renqiang/adWeixin/promotion/ad_forms/miniprogram_chaping.webp" />
                    </div>
                </div>
            </div>
        </div>


        <div class="device">
            <h1>硬件研发</h1>
            <p class="desc">多年硬件研发经验 让您的"老家伙"也能动起来</p>
            <div class="tabs">
                <div @click="(web=0)" :class="['item',{active:web === 0}]">PCB设计</div>
                <div @click="(web=1)" :class="['item',{active:web === 1}]">电脑控制台</div>
                <div @click="(web=2)" :class="['item',{active:web === 2}]">手机操作</div>
            </div>

            <div class="content" v-if="(web === 0)">
                <div class="text">
                    <h2>智能硬件设计</h2>
                    <p class="desc">致力于提供行业领先的物联网场景化应用解决方案</p>
                    <ul class="tags">
                        <li>原理设计</li>
                        <li>PCB设计</li>
                        <li>嵌入式开发</li>
                    </ul>
                </div>
                <div class="vanta" style="padding-bottom: 30px">
                    <img width="200px" src="../../assets/images/pcb.png" />
                </div>
            </div>

            <div class="content" v-if="(web === 1)">
                <div class="text">
                    <h2>智能控制台</h2>
                    <p class="desc">大屏显示服务状态</p>
                    <ul class="tags">
                        <li>原理设计</li>
                        <li>PCB设计</li>
                        <li>嵌入式开发</li>
                        <li>系统开发</li>
                        <li>应用程序开发</li>
                    </ul>
                </div>
                <div class="vanta">
                    <img width="500px" src="../../assets/images/web.png" />
                </div>
            </div>

            <div class="content" v-if="(web === 2)">
                <div class="text">
                    <h2>智能硬件设计</h2>
                    <p class="desc">致力于提供行业领先的物联网场景化应用解决方案</p>
                    <ul class="tags">
                        <li>原理设计</li>
                        <li>PCB设计</li>
                        <li>嵌入式开发</li>
                        <li>系统开发</li>
                        <li>应用程序开发</li>
                    </ul>
                </div>
                <div class="vanta">
                    <img width="480px" src="https://wxa.wxs.qq.com/wxad-design/yijie/delivery.webp" />
                </div>
            </div>

        </div>

        <WebFooter />
    </section>
</template>


<style lang="scss" scoped>
.app {
    .banner {
        width: 100%;
        background: #28292b;
        overflow: hidden;
        margin-top: -80px;
        padding: 150px 0;

        .content {
            max-width: 1120px;
            padding: 0 20px;
            margin: 0 auto;
            position: relative;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 2;
            

            .text{
                flex: 1;
                h2 {
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: .04em;
                    line-height: 50px;
                    position: relative;
                    color: #fff;

                    &:before {
                        content: "";
                        width: 50px;
                        height: 3px;
                        background-color: #00c800;
                        position: absolute;
                        left: 0;
                        top: -20px;
                    }
                }
                p{
                    color: rgba(255, 255, 255, 0.7);
                }
            }

            .logo{
                p{
                    font-family: Courier, monospace;
                    font-size: 16px;
                    font-weight: bold;
                    color: #C678DD;
                    opacity: 0.5;
                }
            }
            
        }

    }

    .apps{
        padding: 50px;
        max-width: 1120px;
        margin: 0 auto;

        h1{
            font-size: 36px;
            color: rgba(0, 0, 0, .88);
        }

        .desc{
            font-size: 18px;
            font-weight: bold;
            color: rgba(0, 0, 0, .88);
        }
        .tabs{
            padding: 30px 0;
            .item{
                margin-right: 24px;
                height: 60px;
                padding: 0px 28px;
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;
                border-radius: 8px;
                transition: all 150ms ease-in-out 0s;
                color: rgba(0, 0, 0, .58);
                background-color: rgb(251, 251, 251);
                box-shadow: rgb(0 0 0 / 2%) 0px 0px 0px 1px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &.active{
                    color: #333;
                }
                &:hover{
                    box-shadow: 0px 4px 10px rgba(0,0,0,0.05);
                    transform: translate3d(0px, -4px, 0px);
                    background-color: rgb(247, 247, 247);
                }

            }
        }
        .content{
            margin: 0 -8px;
            display: flex;
            justify-content: space-between;
            .item{
                padding: 35px 35px 0 35px;
                width: calc(33.333% - 16px);
                margin: 8px;
                border-radius: 15px;
                transform: translate3d(calc(0% - 0px), 0px, 0px);
                box-shadow: rgb(0 0 0 / 4%) 0px 4px 16px 0px, rgb(0 0 0 / 3%) 0px 0px 0px 1px;
                .label{
                    font-size: 14px;
                    color: rgba(0,0,0,0.2);
                    display: flex;
                    align-content: center;
                    span{
                        margin-right: 3px;
                        font-size: 16px;
                    }
                }

                h2{
                    font-size: 24px;
                    color: rgba(0,0,0,0.75);
                }
                .tags{
                    font-size: 14px;
                    color: rgba(0,0,0,0.3);
                }

                .image{
                    margin: 30px auto 0 auto;
                    width: 243px;
                    height: 445px;
                    background: #000;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    border-top: 5px solid #000;
                    border-left: 5px solid #000;
                    border-right: 5px solid #000;
                    overflow: hidden;
                    img{
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                        width: 100%;
                        object-fit: contain;
                        backface-visibility: hidden;
                    }
                }
            }
        }
    }

    .device{
        padding: 50px;
        margin: 0 auto;
        background: #f8f8f8;
        h1{
            font-size: 36px;
            color: rgba(0, 0, 0, .88);
            max-width: 1120px;
            margin: 0 auto;
        }

        .desc{
            max-width: 1120px;
            margin: 0 auto;
            font-size: 18px;
            font-weight: bold;
            color: rgba(0, 0, 0, .88);
        }

        .tabs{
            padding: 30px 0;
            max-width: 1120px;
            margin: 0 auto;
            .item{
                margin-right: 24px;
                height: 60px;
                padding: 0px 28px;
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;
                border-radius: 8px;
                transition: all 150ms ease-in-out 0s;
                color: rgba(0, 0, 0, .58);
                background-color: rgb(251, 251, 251);
                box-shadow: rgb(0 0 0 / 2%) 0px 0px 0px 1px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &.active{
                    color: #333;
                }
                &:hover{
                    box-shadow: 0px 4px 10px rgba(0,0,0,0.05);
                    transform: translate3d(0px, -4px, 0px);
                    background-color: rgb(247, 247, 247);
                }

            }
        }

        .content{
            padding: 50px 80px 0 80px;
            background: #fff;
            border-radius: 15px;
            display: flex;
            max-width: 1120px;
            box-sizing: border-box;
            margin: 0 auto;
            .text{
                flex: 1;
                h2{
                    font-size: 24px;
                    color: #222;
                }
                p.desc{
                    font-size: 16px;
                    color: #666;
                }

                .tags{
                    padding-left: 30px;
                    padding-top: 30px;
                    li{
                        list-style: circle;
                        font-size: 14px;
                        color: #999;
                    }
                }
            }
            .vanta{
                img{
                    
                }
            }
        }
        
    }
}
</style>


<script>
import WebHeader from "../../components/web-header.vue";
import WebFooter from "../../components/web-footer.vue";
import {WeixinMiniApp, WebPage, Iphone, GameConsoleOne} from '@icon-park/vue-next'



export default {
    name: "product",
    components: { WebHeader,WebFooter,WeixinMiniApp, WebPage, Iphone, GameConsoleOne},
    data(){
        return{
            apps: 0,
            web: 0
        }
    },
    mounted(){},
    methods: {}

};
</script>